<template>
  <span>Invoice summary</span>
  <!-- <div class="list">
    <v-list>
      <v-list-item
        two-line
        link
        v-for="i in this.lodash.sortBy(lodash.sampleSize($store.getters.invoices, 20), ['dueDate'])"
        :key="i.id"
        @click.stop="navigateTo(i)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <document-number :document="i" />

          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title>
            <amount
              class="small mb-1"
              :amount="i.amount"
              :currency="i.currency"
              prefixLabel="Amount"
            />
            <amount
              :amount="i.balance"
              :currency="i.currency"
              prefixLabel="Balance"
            />
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle>
            <span>Due date </span>
            <calendar-date :date="i.dueDate" />
            <humanize-date
              v-bind:class="{ 'fgDue': i.dueDate.diff(moment(), 'days') < 0 }"
              :date="i.dueDate"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle>
            <span>Invoice date </span>
            <calendar-date :date="i.invoiceDate" />
            <humanize-date :date="i.invoiceDate" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div> -->
</template>

<script>

export default {
  components: {
  },
  activated () {
  },
  methods: {
    // navigateTo (i) {
    //   this.$router.push('/invoices/' + i.invoiceNumber)
    // }
  }
}
</script>

<style lang="stylus" scoped>
.list
  position relative

  .small
    font-size 0.8em

.list, .v-list, .account-detail
  display flex
  flex-direction column
  flex-grow 1

.v-list
  overflow auto
</style>
